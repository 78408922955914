import { Injectable } from '@angular/core';
import { HttpClient} from '@angular/common/http';
import { Observable, of, Subject, timer, BehaviorSubject } from 'rxjs';
import { GlobalsService } from '../_core/globals.service';
import { ILoggedOnUser } from '../_interface/portal/loggedUser.interface';
//import jwtDecode from 'jwt-decode';
import { PortalGraphQlApiService } from './dx-graphql-api.service';
import { DxControlUtilsService } from '../_shared/dx-components/_common/dx-control-utils.service';
import { IOAuthToken } from '../_interface/portal/oAuth.interface';
import { LoggedOnUserModel } from '../_models/authModels/loggedUser.model';
import { HttpServiceHeader } from '../_constants/user.constants';
import { Router } from '@angular/router';
import { DxToastService } from './dx-notifications.service';

function getWindow():any {
  return window;
}

@Injectable()
export class PortalAuthenticationService {
  private currentUserSubject: BehaviorSubject<LoggedOnUserModel | null>;
  public loggedOnUser: Observable<LoggedOnUserModel | null>;
  unauthorizedSessionUrl!: string;
  keyUser: string = 'dx-current-user';
  showLoader: boolean = false;
  get nativeWindow() {
      return getWindow();
  }
  constructor(
    private http: HttpClient,
    private globalsService: GlobalsService,
    private graphQlAuthSvc: PortalGraphQlApiService,
    private dxSvcHelper: DxControlUtilsService,
    private notifSvc: DxToastService,
    private router: Router
  ) { 
    this.currentUserSubject = new BehaviorSubject<LoggedOnUserModel | null>(
       JSON.parse(String(localStorage.getItem(this.keyUser)))
    );
    this.loggedOnUser = this.currentUserSubject.asObservable();
  }

  public get getLoggedOnUser(): ILoggedOnUser | null {
    return this.currentUserSubject.value;
  }

  private loginUser(email: string, password: string, orgId: number): Observable<IOAuthToken> {
    var loginModel =  {
      "email": email, //"mathew.tupas@demandscience.com",
      "password": password, //"JGI6YUEc56",
      "orgId": orgId
    };

    return this.http.post<IOAuthToken>(`${this.globalsService.env.authUrl}/login`, loginModel);
  }
  menuItems: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
  public loadMainMenus(){
    
     let items = [//{
    //   label:'DX Leads',
    //   icon:'pi pi-fw pi-sitemap',
    //   visible: this.checkClaimsAcess(23),
    //   command: this.routeToApp.bind(this, "Leads")
    // },
    // {
    //   label:'Campaign Info Sync',
    //   icon:'pi pi-fw pi-sitemap',
    //   visible: this.checkClaimsAcess(44),
    //   command: this.routeToApp.bind(this, "CampaignSync/campaigninfo")
    // },
    // {
    //   label:'Campaign Sync',
    //   icon:'pi pi-fw pi-sitemap',
    //   visible: this.checkClaimsAcess(44),
    //   command: this.routeToApp.bind(this, "CampaignSync")
    // },
    // {
    //   label:'Campaign Sync',
    //   icon:'pi pi-fw pi-sitemap',
    //   visible: this.checkClaimsAcess(44),
    //   command: this.routeToApp.bind(this, "CampaignSync")
    // },
    // {
    //   label:'Advance Whitepaper Sync',
    //   icon:'pi pi-fw pi-sitemap',
    //   visible: this.checkClaimsAcess(44),
    //   command: this.routeToApp.bind(this, "CampaignSync/advancewhitepapersync")
    // },
    // {
    //   label:'Whitepaper Sync',
    //   icon:'pi pi-fw pi-sitemap',
    //   visible: this.checkClaimsAcess(44),
    //   command: this.routeToApp.bind(this, "CampaignSync/advancewhitepapersync")
    // },
    {
      label:'ECMS',
      icon:'pi pi-fw pi-sitemap',
      visible: this.checkClaimsAcess(44),
      command: this.routeToApp.bind(this, "CampaignSync/ECMSgenerate")
    },
    {
      label:'Whitepaper Sync Logs',
      icon:'pi pi-fw pi-sitemap',
      visible: this.checkClaimsAcess(44),
      command: this.routeToApp.bind(this, "CampaignSync/advancewhitepaperlogs")
    },  {
      label:'Lead File Extract',
      icon:'pi pi-fw pi-sitemap',
      visible: this.checkClaimsAcess(44),
      command: this.routeToApp.bind(this, "ContactList/leads-files")
    },  {
      label:'Convertr Report',
      icon:'pi pi-fw pi-sitemap',
      visible: this.checkClaimsAcess(44),
      command: this.routeToApp.bind(this, "RevenueReport/revenue-report")
    },
    // {
    //   label:'Whitepaper Sync Logs',
    //   icon:'pi pi-fw pi-sitemap',
    //   visible: this.checkClaimsAcess(44),
    //   command: this.routeToApp.bind(this, "CampaignSync/whitepaperlogs")
    // },
    // {
    //   label:'DX PowerBi',
    //   icon:'pi pi-fw pi-sitemap',
    //   items:[ 
    //     {
    //       label:'Executive Reports',
    //       icon:'pi pi-fw pi-user-edit',
    //       visible: this.checkClaimsAcess(29) || this.checkClaimsAcess(34),
    //       items: [
    //         {
    //           label:'Executive Report (Convertr)',
    //           icon:'pi pi-fw pi-user-edit',
    //           visible: this.checkClaimsAcess(29),
    //           command: this.routeToApp.bind(this, "PowerBi/newreportER")
    //         },{
    //           label:'Channel Performance Report (Admin)',
    //           icon:'pi pi-fw pi-user-edit',
    //           visible: this.checkClaimsAcess(34),
    //           command: this.routeToApp.bind(this, "PowerBi/newreportCPR")
    //         }
    //       ]
    //     },
    //     {
    //       label:'Client Facing Reports',
    //       icon:'pi pi-fw pi-user-edit',
    //       visible: this.checkClaimsAcess(22),
    //       items: [{
    //             label:'Full Version',
    //             visible: this.checkClaimsAcess(22),
    //             icon:'pi pi-fw pi-book',
    //             command: this.routeToApp.bind(this, "PowerBi")
    //         },{
    //             label:'Light Version',
    //             visible: this.checkClaimsAcess(22),
    //             icon:'pi pi-fw pi-users',
    //             command: this.routeToApp.bind(this, "PowerBi/light")
    //         },{
    //           label:'Convertr',
    //           visible: this.checkClaimsAcess(22),
    //           icon:'pi pi-fw pi-user-edit',
    //           command: this.routeToApp.bind(this, "PowerBi/convertr")
    //       }
    //       ]
    //     },
    //     {
    //       label:'Convertr Fullfillment Dashboards',
    //       icon:'pi pi-fw pi-user-edit',
    //       visible: this.checkClaimsAcess(30) || this.checkClaimsAcess(43) || this.checkClaimsAcess(33) || this.checkClaimsAcess(27) || this.checkClaimsAcess(32),
    //       items: [
    //         {
    //           label:'Campaign Fulfillment (Convertr)',
    //           icon:'pi pi-fw pi-user-edit',
    //           visible: this.checkClaimsAcess(30),
    //           command: this.routeToApp.bind(this, "PowerBi/convertrCF")
    //         },{
    //           label:'Digital Delivery Group (Convertr)',
    //           icon:'pi pi-fw pi-user-edit',
    //           visible: this.checkClaimsAcess(43),
    //           command: this.routeToApp.bind(this, "PowerBi/converterFD")
    //         },{
    //           label:'Invalid Leads (Convertr)',
    //           icon:'pi pi-fw pi-user-edit',
    //           visible: this.checkClaimsAcess(33),
    //           command: this.routeToApp.bind(this, "PowerBi/convertrIL")
    //         },{
    //           label:'Partner Business Review (Convertr)',
    //           icon:'pi pi-fw pi-user-edit',
    //           visible: this.checkClaimsAcess(27),
    //           command: this.routeToApp.bind(this, "PowerBi/convertrPBR")
    //         },{
    //           label:'Partner Management (Convertr)',
    //           icon:'pi pi-fw pi-user-edit',
    //           visible: this.checkClaimsAcess(32),
    //           command: this.routeToApp.bind(this, "PowerBi/convertrPM")
    //         }
    //       ]
    //     }, {
    //       label:'Admin Fulfillment Dashboards',
    //       icon:'pi pi-fw pi-user-edit',
    //       visible: this.checkClaimsAcess(31) || this.checkClaimsAcess(28) || this.checkClaimsAcess(35) || this.checkClaimsAcess(36),
    //       items: [
    //         {
    //           label:'Campaign Fulfillment (Admin)',
    //           icon:'pi pi-fw pi-user-edit',
    //           visible: this.checkClaimsAcess(31),
    //           command: this.routeToApp.bind(this, "PowerBi/newreportCF")
    //         },{
    //           label:'Partner Business Review (Admin)',
    //           icon:'pi pi-fw pi-user-edit',
    //           visible: this.checkClaimsAcess(28),
    //           command: this.routeToApp.bind(this, "PowerBi/newreportPBR")
    //         },{
    //           label:'Partner Management (Admin)',
    //           icon:'pi pi-fw pi-user-edit',
    //           visible: this.checkClaimsAcess(35),
    //           command: this.routeToApp.bind(this, "PowerBi/newreportPM")
    //         },{
    //           label:'UQ Dashboard (Admin)',
    //           icon:'pi pi-fw pi-user-edit',
    //           visible: this.checkClaimsAcess(36),
    //           command: this.routeToApp.bind(this, "PowerBi/newreportUQD")
    //         }
    //       ]
    //     }
    //     ]
    // }
  ]
    this.menuItems.next(items);
  }
  public signInSSO(code: string): Observable<any>{
    let payload = {
      "AuthorizationCode": code
    }
    return new Observable<number>((observer => {
      this.http.post<IOAuthToken>(`${this.globalsService.env.ssoApiUrl}/jumpcloudLoggedInToken`, payload).subscribe((oathTokenCallBack: any) => {
        if(oathTokenCallBack.isPasswordResetRequired){
             this.routeToApp(`change-password/${oathTokenCallBack.email}`);
        }else{
          this.graphQlAuthSvc.authuserToken = oathTokenCallBack["id_token"];
          this.graphQlAuthSvc.initApolloConfig();
          this.graphQlAuthSvc.apolloClient.watchQuery({
          query: this.graphQlAuthSvc.getLoggedOnUser(),
              variables:{
                input: { email: oathTokenCallBack.email }
              }
          }).subscribe(userDetails => {
            let loggedUser: ILoggedOnUser = new LoggedOnUserModel();
            loggedUser = this.dxSvcHelper.applyObject(loggedUser, this.graphQlAuthSvc.getDataResponse("getUserByEmail", userDetails).items) as ILoggedOnUser;
            loggedUser.oauthToken = oathTokenCallBack;
            localStorage.setItem(this.keyUser, JSON.stringify(loggedUser));
            this.currentUserSubject.next(loggedUser);
            this.loadMainMenus();
            observer.next(0);
          }, err => {
              console.log(err);
              observer.next(-1);
          });
        }
     }, (err) => {
      console.log(err);
      observer.next(-1);
     });
    }))
  }
  public login(email: string, password: string, orgId: number): Observable<number> {
         return new Observable<number>((observer => {
             this.loginUser(email, password, orgId).subscribe((oathTokenCallBack: any) => {
                if(oathTokenCallBack.isPasswordResetRequired){
                     this.routeToApp(`change-password/${email}`);
                }else{
                  this.graphQlAuthSvc.authuserToken = oathTokenCallBack["id_token"];
                  this.graphQlAuthSvc.initApolloConfig();
                  this.graphQlAuthSvc.apolloClient.watchQuery({
                  query: this.graphQlAuthSvc.getLoggedOnUser(),
                      variables:{
                        input: { email: email }
                      }
                  }).subscribe(userDetails => {
                      //console.log(res);
                      let loggedUser: ILoggedOnUser = new LoggedOnUserModel();
                      //let userObj = {} as ILoggedOnUser;
                      loggedUser = this.dxSvcHelper.applyObject(loggedUser, this.graphQlAuthSvc.getDataResponse("getUserByEmail", userDetails).items) as ILoggedOnUser;
                      //let currentDate = new Date();
                      loggedUser.oauthToken = oathTokenCallBack; //{  access_token: oathTokenCallBack["id_token"],
                    //         token_type: oathTokenCallBack["token_type"],
                    //         //expires_in : oathTokenCallBack["expires_in"],
                    //         //expires_on : new Date(currentDate.setTime(currentDate.getTime() + (oathTokenCallBack["expires_in"] * 1000))),
                    //         dateCreated : new Date(),
                    //         user_guid: oathTokenCallBack["user_guid"],
                    //         refresh_token: oathTokenCallBack["refresh_token"]
                    // } as IOAuthToken
                    localStorage.setItem(this.keyUser, JSON.stringify(loggedUser));
                    this.currentUserSubject.next(loggedUser);
                    this.loadMainMenus();
                    observer.next(0);
                  }, err => {
                      console.log(err);
                      observer.next(-1);
                  });
                }
             }, (err) => {
              console.log(err);
              observer.next(-1);
             });
         }));
  }
  private getLoggedOnUserUsingToken(accessToken: string): Observable<ILoggedOnUser> {
    let authenticatedHeaders = {
      headers: HttpServiceHeader.jsonHeaders.headers.set('Authorization', `Bearer ${accessToken}`)
    };

    return this.http.post<ILoggedOnUser>(`${this.globalsService.env.authUrl}/getloggedonuserusingtoken`, null, authenticatedHeaders);
  }
  public pxLogin(email: string, password: string): Observable<any>{
    var loginModel =  {
      "email": email,
      "password": password,
    };

    return new Observable<any>((observer)=>{
      this.http.post<IOAuthToken>(`${this.globalsService.env.authUrl}/login`, loginModel).subscribe((oathTokenCallBack: IOAuthToken) => {
          this.getLoggedOnUserUsingToken(oathTokenCallBack.id_token).subscribe(data => {
              let loggedUser: ILoggedOnUser = new LoggedOnUserModel();
              loggedUser = this.dxSvcHelper.applyObject(loggedUser, data) as ILoggedOnUser;
              loggedUser.oauthToken = oathTokenCallBack;
              localStorage.setItem(this.keyUser, JSON.stringify(loggedUser));
              this.currentUserSubject.next(loggedUser);
              observer.next(loggedUser);
          });
      }, error => {
          this.showLoader = false;
          this.notifSvc.openErrorMessage("Invalid user login", 3000)
      })
       
   })
  }


  hasClaim(claimType: string, claimValue?:string){
       let hasClaim: boolean = false;

      // if (typeof claimType === "string") {
      //   let userAuth: ILoggedOnUser = this.getCachedUser();
      //   let claimSuper = false;
      //   if(userAuth){
      //     if(userAuth.userType === 1){
      //       let claimPartnerSuper = userAuth.claims.find(c => c.claimType.toLowerCase() == "superpartner");
      //       if(claimPartnerSuper){
      //         claimSuper = true;
      //         let partnersMenu = ["superpartner","purexchange","px.dashboard","px.partnercampaigns","px.leads","px.leadsfile"];
      //         hasClaim = claimPartnerSuper.claimValue === "canSuper" && (partnersMenu.indexOf(claimType) > -1);
      //       }
      //     }else{
      //       let claimObjSuper = userAuth.claims.find(c => c.claimType.toLowerCase() == "super" || userAuth.isAdmin ===  true);
      //       if(claimObjSuper){
      //         claimSuper = true;
      //         hasClaim = claimObjSuper.claimValue === "canSuper"
      //       }
      //     }
      //   }

      //   if(!claimSuper){
      //     hasClaim = this.isClaimValid(claimType, claimValue);
      //   }
      // }
      // else {
      //   let claims: string[] = claimType;
      //   if (claims) {
      //     for (let index = 0; index < claims.length; index++) {
      //       hasClaim = this.isClaimValid(claims[index]);
      //       if (hasClaim) {
      //         break;
      //       }
      //     }
      //   }
      // }

      //TODO: Isolate to lead delivery and px
     /* if(claimType.toLocaleLowerCase() === "leaddelivery"){
        let ladClaimObject = this.loggedOnUser.claims.find(c => c.claimType.toLowerCase() == "leaddelivery");
        if(!ladClaimObject){
            hasClaim = false;
        }
      }

      if(claimType.toLocaleLowerCase() === "purexchange"){
        let ladClaimObject = this.loggedOnUser.claims.find(c => c.claimType.toLowerCase() == "purexchange");
        if(!ladClaimObject){
            hasClaim = false;
        }
      }*/

    return hasClaim;
  }


  // isClaimValid(claimType: string, claimValue?:string): boolean{
  //   let isValidClaim: boolean = false;
  //   let userAuth: ILoggedOnUser = this.loggedOnUser;

  //   if(userAuth){
      
  //      if(claimType.indexOf("=") >= 0){
  //       let words: string[] = claimType.split("=");
  //       claimType = words[0].toLowerCase();
  //       claimValue = words[1];
  //      }else{
  //       claimType = claimType.toLowerCase();
  //       claimValue = claimValue ? claimValue : "true";
  //      }

  //       let claimObj = userAuth.claims.find(c => c.claimType.toLowerCase() == claimType);
  //       if(claimObj && claimObj.claimValue.indexOf(":") >= 0){
  //         let valWords: string[] = claimObj.claimValue.split(":");
  //         valWords.forEach(v => {
  //             if(claimValue === v){
  //                 isValidClaim = true;
  //             }
  //         });
  //       }else{
  //         isValidClaim = userAuth.claims.find(c =>
  //         c.claimType.toLowerCase() == claimType &&
  //         c.claimValue == claimValue) != null;
  //       }
  //   }
  //   return isValidClaim;
  // }

  validateMultiPleClaimValue(claimValue: string){

  }

  private _timeoutInSeconds: number = 5;//3000;
  timer!: Observable<number>;
  onTimeOutExpired: Subject<number> = new Subject<number>();

  startSession(){
      
      let inSec = this._timeoutInSeconds * 60000;
      this.timer = timer(2000, inSec);
      this.timer.subscribe(((num: number) => {
         if(num !== 0){
          this.refreshTokenRequest();
         }
      }).bind(this));
  }

  refreshTokenRequest(){
    //  if(this.loggedOnUser){
    //     let refToken = this.loggedOnUser.oauthToken["refresh_token"];
    //     this.http.post<IOAuthToken>(`${this.globalsService.env.authUrl}/refreshtoken`, {
    //         user_guid: this.loggedOnUser.oauthToken["user_guid"],
    //         refresh_token: refToken
    //     }).subscribe(objRes => {
    //       this.loggedOnUser.oauthToken = {  access_token: objRes["id_token"],
    //         token_type: objRes["token_type"],
    //         expires_in : objRes["expires_in"],
    //         expires_on : new Date(),
    //         dateCreated : new Date(),
    //         user_guid: objRes["user_guid"],
    //         refresh_token: refToken
    //       } as IOAuthToken
    //       this.setCachedUser(this.loggedOnUser);
    //     });
    //  }
  }

  checkClaimsAcess(groupId: number): boolean{
    let currentUser: any = this.getCachedUser();
    // Get property name on security object to check

    let hasClaim = currentUser? currentUser.groups.find((a:any) => a.id === groupId) :  false;
    if(hasClaim){
      return true;
    }else{
      if(currentUser.groups.find((a:any) => a.id === 5)){
        return true
      }else{
        //this.router.navigate(['unauthorized']);
        return false;
      }
    }
  }

  resetPassword(email: string) {
    let userModel = {
        "email": email
    }
    return this.http.post<any>(`${this.globalsService.env.dxCampaignSyncUrl}/user/resetPassword`, userModel);
  }

  changePassword(formData: any) {
    let changePasswordData = {
      "email": formData.username,
      "oldPassword": formData.oldpassword,
      "newPassword": formData.newpassword,
      //"user_guid": this.loggedOnUser.oauthToken.user_guid
    };

    return this.http.post<IOAuthToken>(`${this.globalsService.env.dxCampaignSyncUrl}/user/changePassword`, changePasswordData);
  }


  public setUnauthorizedSessionUrl(url: string): void {
    this.unauthorizedSessionUrl = url;
  }

  public getCachedUser(): ILoggedOnUser{
    return JSON.parse(String(localStorage.getItem(this.keyUser)));
  }
  routeToApp(app: string){
    this.router.navigate([app])
  }
  logout(){
    localStorage.removeItem(this.keyUser);
    if(this.currentUserSubject.value != null){
      this.currentUserSubject.next(null);
    }
  }
}
